import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

const LoginButton = ({ currentLocale }) => {
	const intl = useIntl();
	const LoginButtonClassName = "text-xs text-slate-900 border border-transparent px-4 py-2 rounded-md hover:bg-slate-200 transition-colors duration-300";
	return (
		<Link
			to={currentLocale === 'en' ? '/en/signin' : '/signin'}
			className={LoginButtonClassName}
		>
			{intl.formatMessage({ id: 'auth.signin' })}
		</Link>
	);
};

export default LoginButton;