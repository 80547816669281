const messages = {
	'books.count': 'books are introduced!',
	'sort.newest': 'Newest First',
	'sort.oldest': 'Oldest First',
	'view.list': 'List View',
	'view.grid': 'Grid View',
	'search.placeholder': 'Search books by title',
	'search.noResults': 'No books found.',
	'search.tryOther': 'Please try different search criteria.',
	'search.open': 'Open Search',
	'button.clearFilters': 'Clear Filters',
	'nav.about': 'About',
	'tag.design': 'Design',
	'tag.branding': 'Branding',
	'tag.interaction': 'Interaction',
	'tag.review': 'Review',
	'tag.typography': 'Typography',
	'tag.social': 'Social',
	'tag.graphic': 'Graphic',
	'tag.grid': 'Grid',
	'tag.ux': 'UX',
	'tag.ui': 'UI',
	'tag.research': 'Research',
	'tag.method': 'Method',
	'tag.theory': 'Theory',
	'tag.design-system': 'Design System',
	'tag.facilitation': 'Facilitation',
	'tag.workshop': 'Workshop',
	'tag.web': 'Web',
	'tag.dtp': 'DTP',
	'tag.layout': 'Layout',
	'tag.psychology': 'Psychology',
	'tag.service': 'Service Design',
	'tag.infographic': 'Infographic',
	'tag.storytelling': 'Storytelling',
	'tag.advertising': 'Advertising',
	'tag.color': 'Color',
	'tag.ia': 'Information Architecture',
	'tag.font': 'Font',
	'tag.economy': 'Economy',
	'tag.logo': 'Logo',
	'tag.figma': 'Figma',
	'tag.accessibility': 'Accessibility',
	'tag.prototyping': 'Prototyping',
	'tag.system-thinking': 'System Thinking',
	'tag.ai': 'AI',
	'tag.package': 'Package',
	'tag.japanese': 'Japanese',
	'tag.all': 'All',
	'auth.signin': 'Sign In',
	'auth.signup': 'Sign Up',
	'auth.signout': 'Sign Out',
	'auth.email': 'Email',
	'auth.password': 'Password',
	'auth.password.confirm': 'Confirm Password',
	'auth.password.mismatch': 'Passwords do not match',
	'auth.signin.button': 'Sign In',
	'auth.signup.button': 'Sign Up',
	'auth.signin.google': 'Continue with Google',
	'auth.signup.google': 'Continue with Google',
	'auth.or': 'Continue with other method',
	'auth.no.account': 'Don\'t have an account?',
	'auth.have.account': 'Already have an account?',
	'auth.signup.link': 'Sign Up',
	'auth.signin.link': 'Sign In',
	'favorite.login.required': 'Please login to add to favorites',
	'favorite.add.success': 'Added to favorites',
	'favorite.remove.success': 'Removed from favorites',
	'favorite.add': 'Add to favorites',
	'favorite.remove': 'Remove from favorites',
	'favorite.title': 'Favorite Books',
	'favorite.empty': 'No favorite books yet',
	'nav.favorites': 'Favorites',
	'settings.title': 'Settings',
	'settings.profile': 'Profile',
	'settings.email': 'Email Address',
	'settings.delete.account': 'Delete Account',
	'settings.delete.account.description': 'Deleting your account will permanently remove all your data and cannot be undone.',
	'settings.delete.account.button': 'Delete Account',
	'settings.delete.account.confirm': 'Are you sure you want to delete your account? This action cannot be undone.',
	'settings.delete.account.success': 'Your account has been successfully deleted. Thank you for using our service.',
	'settings.delete.account.error': 'An error occurred while deleting your account. Please try again later.',
	'settings.delete.account.password': 'Enter your current password',
	'settings.delete.account.password.placeholder': 'Enter password',
	'settings.delete.account.auth.error': 'Authentication failed. Please check your password and try again.',
	'common.cancel': 'Cancel',
	'settings.delete.account.confirm.button': 'Confirm Delete Account',
	'search.results': 'Search results for "{query}"',
	'search.with.term': 'Search for "{term}"',
	'book.not.found': 'Book not found',
	'back.to.home': 'Back to Home',
	'book.description': 'Description',
	'book.details': 'Details',
	'book.author': 'Author',
	'book.publisher': 'Publisher',
	'book.published.date': 'Published Date',
	'book.tags': 'Tags',

	// Meta tag messages
	'meta.default.description': 'A site that makes it easy to find and discover design books.',
	'meta.home.title': 'Find and Discover Design Books',
	'meta.tag.title': 'Design Books about {tag}',
	'meta.tag.description': 'A curated collection of design books related to {tag}. Discover carefully selected books to deepen your design knowledge.',
	'meta.book.description': '"{title}" by {author} - Details about this design book. A carefully selected book to deepen your design knowledge.',
	'meta.search.title': 'Search Results for "{query}"',
	'meta.search.description': 'Browse design books related to "{query}". We introduce books from various genres such as UI/UX, graphics, typography, and more.',
	'meta.about.title': 'About Us',
	'meta.about.description': 'Design Book Collection is a curation site that makes it easy to find and discover design books. We introduce books from various genres such as UI/UX, graphics, typography, and more.',
	'meta.settings.title': 'Account Settings',
	'meta.settings.description': 'Manage your Design Book Collection account settings and profile information.',
	'meta.favorites.title': 'Favorite Books',
	'meta.favorites.description': 'View your collection of favorite design books.',
};

export default messages; 