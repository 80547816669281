import React from 'react';
import { useIntl } from 'react-intl';
import LanguageSwitcher from './LanguageSwitcher';
import { useLocale } from '../../contexts/LocaleContext';

const Footer = () => {
	const { locale, handleLanguageChange } = useLocale();
	const intl = useIntl();

	return (
		<footer className="text-gray-700 p-4 text-xs flex justify-between items-center">
			<ul className="flex space-x-4">
				<li>
					<a className="hover:underline" href="/about">
						{intl.formatMessage({ id: 'nav.about' })}
					</a>
				</li>
			</ul>
			<p>© 2025 Design Book Collection</p>
			<LanguageSwitcher onLanguageChange={handleLanguageChange} currentLocale={locale} />
		</footer>
	);
};

export default Footer;
