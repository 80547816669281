import React, { useState, useRef, useEffect } from 'react';
import { IoClose, IoSearch } from "react-icons/io5";
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useBooks } from '../../../hooks/useBooks';

const SearchBar = () => {
	const intl = useIntl();
	const navigate = useNavigate();
	const [searchTerm, setSearchTerm] = useState('');
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [isSearchOpen, setIsSearchOpen] = useState(false);
	const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
	const { books } = useBooks();
	const suggestionsRef = useRef(null);
	const searchBarRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
				setIsSearchOpen(false);
			}
			if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
				setShowSuggestions(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const suggestions = books
		.filter(book =>
			book.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
			searchTerm.length > 0
		)
		.slice(0, 5);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (searchTerm.trim()) {
			navigate(`/search?q=${encodeURIComponent(searchTerm.trim())}`);
			setShowSuggestions(false);
			setIsSearchModalOpen(false);
		}
	};

	const handleSuggestionClick = (title) => {
		setSearchTerm(title);
		navigate(`/search?q=${encodeURIComponent(title)}`);
		setShowSuggestions(false);
		setIsSearchModalOpen(false);
	};

	const handleClear = () => {
		setSearchTerm('');
		setShowSuggestions(false);
	};

	const handleInputChange = (e) => {
		setSearchTerm(e.target.value);
		setShowSuggestions(true);
	};

	const toggleSearch = () => {
		setIsSearchOpen(!isSearchOpen);
		setIsSearchModalOpen(!isSearchModalOpen);
		if (!isSearchOpen) {
			setTimeout(() => {
				searchBarRef.current?.querySelector('input')?.focus();
			}, 100);
		}
	};

	return (
		<div className="relative" ref={searchBarRef}>
			{/* モバイル用検索アイコン */}
			<button
				onClick={toggleSearch}
				className="md:hidden p-2 hover:bg-gray-100 rounded-full"
				aria-label={intl.formatMessage({ id: 'search.open' })}
			>
				<IoSearch size={24} />
			</button>

			{/* 検索モーダル（モバイル用） */}
			{isSearchModalOpen && (
				<div className="fixed inset-0 bg-black bg-opacity-50 z-50 md:hidden">
					<div className="bg-white w-full h-full">
						<div className="flex items-center p-4 border-b">
							<form onSubmit={handleSubmit} className="flex-1">
								<input
									type="text"
									placeholder={intl.formatMessage({ id: 'search.placeholder' })}
									value={searchTerm}
									onChange={handleInputChange}
									className="w-full p-2 border rounded"
									autoFocus
								/>
							</form>
							<button
								onClick={toggleSearch}
								className="p-2 hover:bg-gray-100 rounded-full mr-2"
							>
								<IoClose size={24} />
							</button>
						</div>
						<div className="overflow-y-auto h-[calc(100%-4rem)]">
							<div className="p-4">
								{searchTerm && (
									<button
										className="w-full text-left px-4 py-4 hover:bg-gray-100 text-sm border-b"
										onClick={() => handleSuggestionClick(searchTerm)}
									>
										🔍 {intl.formatMessage(
											{ id: 'search.with.term' },
											{ term: searchTerm }
										)}
									</button>
								)}
								{suggestions.map((book) => (
									<button
										key={book.id}
										className="w-full text-left px-4 py-3 hover:bg-gray-100 text-sm border-b"
										onClick={() => handleSuggestionClick(book.title)}
									>
										{book.title}
									</button>
								))}
							</div>
						</div>
					</div>
				</div>
			)}

			{/* デスクトップ用検索バー */}
			<div className={`
				absolute right-0 top-0 md:relative
				${isSearchOpen ? 'hidden' : 'hidden'} md:flex
				w-screen md:w-96 px-4 md:px-0
				h-16 md:h-auto
				items-center
				bg-white md:bg-transparent
				z-50
			`}>
				<form onSubmit={handleSubmit} className="text-sm relative w-full">
					<input
						type="text"
						placeholder={intl.formatMessage({ id: 'search.placeholder' })}
						value={searchTerm}
						onChange={handleInputChange}
						className="p-2 border rounded w-full"
						onFocus={() => setShowSuggestions(true)}
					/>
					{searchTerm && (
						<button
							type="button"
							onClick={handleClear}
							className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
							aria-label={intl.formatMessage({ id: 'button.clearFilters' })}
						>
							<IoClose size={20} />
						</button>
					)}
				</form>
				{showSuggestions && searchTerm && (
					<div className="absolute z-50 w-full left-0 md:left-auto mt-1 top-full bg-white border rounded-md shadow-lg max-h-[300px] overflow-y-auto">
						<button
							className="w-full text-left px-4 py-4 hover:bg-gray-100 text-sm border-b"
							onClick={() => handleSuggestionClick(searchTerm)}
						>
							🔍 {intl.formatMessage(
								{ id: 'search.with.term' },
								{ term: searchTerm }
							)}
						</button>
						{suggestions.map((book) => (
							<button
								key={book.id}
								className="w-full text-left px-4 py-2 hover:bg-gray-100 text-sm"
								onClick={() => handleSuggestionClick(book.title)}
							>
								{book.title}
							</button>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default SearchBar;