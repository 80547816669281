import React, { useState } from 'react';
import BookModal from './BookModal';
import { useIntl } from 'react-intl';
import Skeleton from '@mui/material/Skeleton';
import FavoriteButton from '../Favorite/FavoriteButton';
const BookList = ({ books }) => {
	const [selectedBook, setSelectedBook] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const intl = useIntl();
	const openModal = (book) => {
		setSelectedBook(book);
	};

	const closeModal = () => {
		setSelectedBook(null);
	};

	return (
		<>
			<ul className="list-none pl-0">
				{books.map((book, index) => (
					<li key={index} className="flex items-center border-b border-gray-200 py-4">
						<div className="flex items-center flex-grow">
							<button
								onClick={() => openModal(book)}
								className="flex items-center flex-grow text-left"
							>
								<img src={book.image} alt={book.title} className="w-16 h-24 object-cover mr-4 shadow-md" onLoad={() => setIsLoading(false)} />
								{isLoading && (
									<Skeleton variant="rectangular" width={64} height={96} className="mr-4" />
								)}
								<div className="flex-grow">
									<h3 className="font-medium">{book.title}</h3>
									<p className="text-sm text-gray-600">{book.author}</p>
									<p className="text-xs text-gray-500">{book.publishedDate}</p>
									<div className="flex flex-wrap mt-1">
										{book.tags?.map((tag, tagIndex) => (
											<span key={tagIndex} className="bg-gray-200 text-gray-800 text-xs mr-2 px-2 py-0.5 rounded-full">
												{intl.formatMessage({ id: `tag.${tag.toLowerCase()}` })}
											</span>
										))}
									</div>
								</div>
							</button>
							<div className="ml-4">
								<FavoriteButton bookId={book.id} />
							</div>
						</div>
					</li>
				))}
			</ul>
			<BookModal book={selectedBook} onClose={closeModal} />
		</>
	);
};

export default BookList; 