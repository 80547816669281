import React, { useState, useEffect } from 'react';
import { useBooks } from '../../hooks/useBooks';
import BookGrid from './BookGrid';
import BookList from './BookList';
import { useIntl } from 'react-intl';
import NotFoundBook from './NotFoundBook';
import SideBar from './Sidebar';
import { useParams, useNavigate } from 'react-router-dom';
import jaMessages from '../../i18n/messages/ja';
import enMessages from '../../i18n/messages/en';
import { Helmet } from 'react-helmet-async';
import FilterButton from './FilterButton';
import { useAuth } from '../../contexts/AuthContext';
import { auth } from '../../firebase/config';
import { signOut } from 'firebase/auth';
import Header from '../_common/Header';
import { useLocale } from '../../contexts/LocaleContext';

const messages = {
	ja: jaMessages,
	en: enMessages
};

const Main = () => {
	const { locale, handleLanguageChange } = useLocale();
	const { books, loading, error } = useBooks();
	const [sortedBooks, setSortedBooks] = useState([]);
	const [sortOption, setSortOption] = useState('新しい');
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [isGridView, setIsGridView] = useState(true);
	const { tag } = useParams();
	const navigate = useNavigate();
	const intl = useIntl();
	const [selectedTags, setSelectedTags] = useState(tag ? [tag] : []);
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const { user } = useAuth();

	useEffect(() => {
		if (books.length > 0) {
			const initialSortedBooks = [...books].sort((a, b) => {
				return new Date(b.publishedDate) - new Date(a.publishedDate);
			});
			setSortedBooks(initialSortedBooks);
		}
	}, [books]);

	useEffect(() => {
		if (tag) {
			setSelectedTags([tag]);
			const filtered = filterBooks(books, [tag]);
			const sorted = sortByDate(filtered, sortOption === intl.formatMessage({ id: 'sort.newest' }) ? '新しい' : '古い');
			setSortedBooks(sorted);
		}
	}, [tag, books]);

	const filterBooks = (books, tags) => {
		return books.filter(book => {
			const matchesTags = tags.length === 0 || (tags.length === 1 && book.tags && book.tags.includes(tags[0]));
			return matchesTags;
		});
	};

	const sortByDate = (books, order) => {
		return [...books].sort((a, b) => {
			return order === '新しい'
				? new Date(b.publishedDate) - new Date(a.publishedDate)
				: new Date(a.publishedDate) - new Date(b.publishedDate);
		});
	};

	const handleSortOptionChange = (option) => {
		setSortOption(option);
		setIsDropdownOpen(false);
		const filtered = filterBooks(books, [option]);
		const sorted = sortByDate(filtered, option === intl.formatMessage({ id: 'sort.newest' }) ? '新しい' : '古い');
		setSortedBooks(sorted);
	};

	const handleTagSelect = (selectedTag) => {
		if (selectedTags[0] === selectedTag) {
			return;
		}

		const newTags = selectedTag === 'all' ? [] : [selectedTag];
		setSelectedTags(newTags);

		if (window.innerWidth < 768) {
			setIsSidebarOpen(false);
		}

		const locale = intl.locale;
		const prefix = locale === 'en' ? '/en' : '';

		if (newTags.length > 0) {
			const tagKey = Object.keys(messages[locale]).find(
				key => key.startsWith('tag.') &&
					intl.formatMessage({ id: key }) === intl.formatMessage({ id: `tag.${selectedTag.toLowerCase()}` })
			);
			const tagPath = tagKey ? tagKey.replace('tag.', '') : selectedTag.toLowerCase();
			navigate(`${prefix}/${tagPath}`);
		} else {
			navigate(prefix || '/');
		}

		const filtered = filterBooks(books, newTags);
		const sorted = sortByDate(filtered, sortOption);
		setSortedBooks(sorted);
	};

	const clearFilters = () => {
		setSelectedTags([]);

		const locale = intl.locale;
		const prefix = locale === 'en' ? '/en' : '';

		navigate(prefix || '/');
		const sorted = sortByDate(books, sortOption);
		setSortedBooks(sorted);
	};

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	const getCanonicalUrl = () => {
		const baseUrl = 'https://design-book-collection.com';
		if (tag) {
			return `${baseUrl}/${tag}`;
		}
		return baseUrl;
	};

	const handleSignOut = async () => {
		try {
			await signOut(auth);
			navigate('/');
		} catch (error) {
			console.error('Error signing out:', error);
		}
	};

	// メタタグ用のタイトルと説明を生成
	const getMetaTags = () => {
		if (tag) {
			const tagLabel = intl.formatMessage({ id: `tag.${tag.toLowerCase()}` });
			return {
				title: intl.formatMessage({ id: 'meta.tag.title' }, { tag: tagLabel }),
				description: intl.formatMessage({ id: 'meta.tag.description' }, { tag: tagLabel }),
			};
		}
		return {
			title: intl.formatMessage({ id: 'meta.home.title' }),
			description: intl.formatMessage({ id: 'meta.default.description' }),
		};
	};

	const metaTags = getMetaTags();

	if (loading) {
		return <div className="flex justify-center items-center h-screen">Loading...</div>;
	}

	if (error) {
		return <div className="flex justify-center items-center h-screen text-red-500">{error}</div>;
	}

	const availableTags = [...new Set(books.flatMap(book => book.tags || []))];

	return (
		<>
			<Helmet>
				<title>{metaTags.title}</title>
				<meta name="description" content={metaTags.description} />
				<meta property="og:title" content={metaTags.title} />
				<meta property="og:description" content={metaTags.description} />
				<meta property="og:url" content={`https://design-book-collection.com${locale === 'en' ? '/en' : ''}${tag ? `/${tag}` : ''}`} />
				<link rel="canonical" href={`https://design-book-collection.com${locale === 'en' ? '/en' : ''}${tag ? `/${tag}` : ''}`} />
			</Helmet>
			<div className="flex flex-col h-screen">
				<Header
					isGridView={isGridView}
					setIsGridView={setIsGridView}
					isDropdownOpen={isDropdownOpen}
					setIsDropdownOpen={setIsDropdownOpen}
					sortOption={sortOption}
					handleSortOptionChange={handleSortOptionChange}
					handleSignOut={handleSignOut}
					currentLocale={locale}
					onLanguageChange={handleLanguageChange}
				/>
				<div className="flex flex-1 overflow-hidden">
					{/* スクリムのオーバーレイ */}
					{isSidebarOpen && (
						<div
							className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity md:hidden z-10"
							onClick={toggleSidebar}
						/>
					)}
					<aside className={`fixed md:static transition-transform duration-300 bg-white overflow-y-auto ${isSidebarOpen
						? 'translate-x-0 z-20 top-0 bottom-0'
						: '-translate-x-full md:translate-x-0'
						}`}>
						<SideBar
							availableTags={availableTags}
							selectedTags={selectedTags}
							handleTagSelect={handleTagSelect}
						/>
					</aside>
					<div className="flex-1 overflow-y-auto">
						<main className="container mx-auto p-4">
							<section id="book-list">
								{sortedBooks.length > 0 ? (
									isGridView ? (
										<BookGrid books={sortedBooks} />
									) : (
										<BookList books={sortedBooks} />
									)
								) : (
									<NotFoundBook clearFilters={clearFilters} />
								)}
							</section>
						</main>
					</div>
				</div>
				<FilterButton toggleSidebar={toggleSidebar} />
			</div>
		</>
	);
};

export default Main;
