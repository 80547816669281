import React, { useEffect } from 'react';
import { IntlProvider, useIntl } from 'react-intl';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Main from './components/Main';
import SignIn from './components/Auth/SignIn';
import SignUp from './components/Auth/SignUp';
import { AuthProvider } from './contexts/AuthContext';
import { LocaleProvider, useLocale } from './contexts/LocaleContext';
import jaMessages from './i18n/messages/ja';
import enMessages from './i18n/messages/en';
import About from './components/About';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import FavoriteBooks from './components/Favorite/FavoriteBooks';
import Settings from './components/Settings';
import SearchResults from './components/Search/SearchResults';
import { useBooks } from './hooks/useBooks';
import BookDetail from './components/Book';
import GTMTracking, { initGTM } from './components/_common/TagManager';

const messages = {
	ja: jaMessages,
	en: enMessages
};

function IntlProviderWrapper({ children }) {
	const { locale } = useLocale();
	return (
		<IntlProvider messages={messages[locale]} locale={locale}>
			{children}
		</IntlProvider>
	);
}

function AppContent() {
	const { locale, handleLanguageChange } = useLocale();
	const intl = useIntl();
	const { books } = useBooks();

	// URLのパスから言語を設定
	useEffect(() => {
		const path = window.location.pathname;
		if (path.startsWith('/en')) {
			handleLanguageChange('en');
		} else if (!path.startsWith('/en') && !navigator.language.toLowerCase().startsWith('ja')) {
			window.location.pathname = '/en' + path;
		}
	}, []);

	return (
		<>
			<Helmet defaultTitle="Design Book Collection" titleTemplate="%s | Design Book Collection">
				<html lang={locale} />
				<meta name="description" content={intl.formatMessage({ id: 'meta.default.description' })} />
				<meta property="og:site_name" content="Design Book Collection" />
				<meta property="og:type" content="website" />
				<meta property="og:image" content="https://design-book-collection.com/ogp.png" />
				<meta name="twitter:card" content="summary_large_image" />
			</Helmet>
			<Routes>
				<Route path="/search" element={<SearchResults books={books} />} />
				<Route path="/en/search" element={<SearchResults books={books} />} />
				<Route path="/favorites" element={<FavoriteBooks />} />
				<Route path="/en/favorites" element={<FavoriteBooks />} />
				<Route path="/settings" element={<Settings />} />
				<Route path="/en/settings" element={<Settings />} />
				<Route path="/" element={<Main />} />
				<Route path="/en" element={<Main />} />
				<Route path="/:tag" element={<Main />} />
				<Route path="/en/:tag" element={<Main />} />
				<Route path="/books/:id" element={<BookDetail />} />
				<Route path="/en/books/:id" element={<BookDetail />} />
				<Route path="/about" element={<About />} />
				<Route path="/en/about" element={<About />} />
				<Route path="/signin" element={<SignIn />} />
				<Route path="/en/signin" element={<SignIn />} />
				<Route path="/signup" element={<SignUp />} />
				<Route path="/en/signup" element={<SignUp />} />
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		</>
	);
}

function App() {
	useEffect(() => {
		initGTM();
	}, []);

	return (
		<HelmetProvider>
			<AuthProvider>
				<LocaleProvider>
					<IntlProviderWrapper>
						<Router>
							<GTMTracking />
							<AppContent />
						</Router>
					</IntlProviderWrapper>
				</LocaleProvider>
			</AuthProvider>
		</HelmetProvider>
	);
}

export default App;
