import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

const SignupButton = ({ currentLocale }) => {
	const intl = useIntl();
	const SignupButtonClassName = "font text-xs text-white border border-slate-900 px-4 py-2 rounded-md bg-slate-900";
	return (
		<Link
			to={currentLocale === 'en' ? '/en/signup' : '/signup'}
			className={SignupButtonClassName}
		>
			{intl.formatMessage({ id: 'auth.signup' })}
		</Link>
	);
};

export default SignupButton;