import React, { useState, useEffect, useRef } from 'react';
import SearchBar from './SearchBar';
import LoginButton from './LoginButton';
import SignupButton from './SignupButton';
import UserMenu from './UserMenu';
import UserAvatar from './UserAvatar';
import { useAuth } from '../../../contexts/AuthContext';
import { signOut } from 'firebase/auth';
import { auth } from '../../../firebase/config';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/logo.svg';
import logoSmall from '../../../assets/logo-2.svg';

const Header = ({
	isGridView,
	setIsGridView,
	isDropdownOpen,
	setIsDropdownOpen,
	sortOption,
	handleSortOptionChange,
	currentLocale,
}) => {
	const { user } = useAuth();
	const navigate = useNavigate();
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const menuRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setIsMenuOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleSignOut = async () => {
		try {
			await signOut(auth);
			navigate('/');
		} catch (error) {
			console.error('Error signing out:', error);
		}
	};

	return (
		<header className="sticky top-0 z-[5] flex justify-between items-center p-4 border-b border-gray-200 bg-white">
			<div
				onClick={() => navigate('/')}
				className="cursor-pointer"
			>
				<picture>
					<source media="(min-width: 768px)" srcSet={logo} />
					<img src={logoSmall} alt="logo" className="w-auto h-8 md:h-12" />
				</picture>
			</div>
			<SearchBar />
			<div className="flex justify-end items-center gap-4">
				{/* <ViewToggle isGridView={isGridView} setIsGridView={setIsGridView} /> */}
				{/* <SortDropdown
					isDropdownOpen={isDropdownOpen}
					toggleDropdown={() => setIsDropdownOpen(!isDropdownOpen)}
					sortOption={sortOption}
					handleSortOptionChange={handleSortOptionChange}
				/> */}
				{user ? (
					<div className="relative" ref={menuRef}>
						<UserAvatar onClick={() => setIsMenuOpen(!isMenuOpen)} />
						{isMenuOpen && (
							<UserMenu
								isMenuOpen={isMenuOpen}
								setIsMenuOpen={setIsMenuOpen}
								currentLocale={currentLocale}
								handleSignOut={handleSignOut}
							/>
						)}
					</div>
				) : (
					<>
						<LoginButton currentLocale={currentLocale} />
						<SignupButton currentLocale={currentLocale} />
					</>
				)}
			</div>
		</header>
	);
};

export default Header; 