import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

const SignUpLink = () => {
	const intl = useIntl();

	return (
		<div className="mt-6 text-center">
			<p className="text-gray-600">
				{intl.formatMessage({ id: 'auth.no.account' })}
				{' '}
				<Link to="/signup" className="text-blue-500 hover:text-blue-600 font-medium">
					{intl.formatMessage({ id: 'auth.signup.link' })}
				</Link>
			</p>
		</div>
	);
};

export default SignUpLink; 