const messages = {
	'books.count': '冊のデザイン本を紹介中！',
	'sort.newest': '出版日が新しい順',
	'sort.oldest': '出版日が古い順',
	'view.list': 'リスト表示',
	'view.grid': 'グリッド表示',
	'search.placeholder': '本のタイトルを検索',
	'search.open': '検索を開く',
	'search.noResults': '該当する本はありませんでした。',
	'search.tryOther': '他の検索条件をお試しください。',
	'button.clearFilters': '検索条件をクリア',
	'nav.about': 'このサイトについて',
	'tag.design': 'デザイン',
	'tag.branding': 'ブランディング',
	'tag.interaction': 'インタラクション',
	'tag.review': 'レビュー',
	'tag.typography': 'タイポグラフィ',
	'tag.social': '社会',
	'tag.graphic': 'グラフィック',
	'tag.grid': 'グリッド',
	'tag.ux': 'UX',
	'tag.ui': 'UI',
	'tag.research': 'リサーチ',
	'tag.method': '手法',
	'tag.theory': '理論',
	'tag.design-system': 'デザインシステム',
	'tag.facilitation': 'ファシリテーション',
	'tag.workshop': 'ワークショップ',
	'tag.web': 'Web',
	'tag.dtp': 'DTP',
	'tag.layout': 'レイアウト',
	'tag.psychology': '心理学',
	'tag.service': 'サービスデザイン',
	'tag.infographic': 'インフォグラフィック',
	'tag.storytelling': 'ストーリーテリング',
	'tag.advertising': '広告',
	'tag.color': '色',
	'tag.ia': '情報アーキテクチャ',
	'tag.font': 'フォント',
	'tag.economy': '経済',
	'tag.logo': 'ロゴ',
	'tag.figma': 'Figma',
	'tag.package': 'パッケージ',
	'tag.accessibility': 'アクセシビリティ',
	'tag.prototyping': 'プロトタイピング',
	'tag.system-thinking': 'システム思考',
	'tag.ai': 'AI',
	'tag.japanese': '日本',
	'tag.all': 'すべて',
	'auth.signin': 'ログイン',
	'auth.signup': '新規登録',
	'auth.signout': 'ログアウト',
	'auth.email': 'メールアドレス',
	'auth.password': 'パスワード',
	'auth.password.confirm': 'パスワード（確認）',
	'auth.password.mismatch': 'パスワードが一致しません',
	'auth.signin.button': 'ログイン',
	'auth.signup.button': '新規登録',
	'auth.signin.google': 'Googleでログイン',
	'auth.signup.google': 'Googleで新規登録',
	'auth.or': 'その他の方法でログイン',
	'auth.no.account': 'アカウントをお持ちでない方は',
	'auth.have.account': 'すでにアカウントをお持ちの方は',
	'auth.signup.link': '新規登録',
	'auth.signin.link': 'ログイン',
	'favorite.login.required': 'お気に入りに追加するにはログインが必要です',
	'favorite.add.success': 'お気に入りに追加しました',
	'favorite.remove.success': 'お気に入りから削除しました',
	'favorite.add': 'お気に入りに追加',
	'favorite.remove': 'お気に入りから削除',
	'favorite.title': 'お気に入りの本',
	'favorite.empty': 'お気に入りの本がありません',
	'nav.favorites': 'お気に入り',
	'settings.title': '設定',
	'settings.profile': 'プロフィール',
	'settings.email': 'メールアドレス',
	'settings.delete.account': 'アカウントの削除',
	'settings.delete.account.description': 'アカウントを削除すると、すべてのデータが完全に削除され、元に戻すことはできません。',
	'settings.delete.account.button': '退会する',
	'settings.delete.account.confirm': '本当に退会してもよろしいですか？この操作は取り消すことができません。',
	'settings.delete.account.success': '退会処理が完了しました。ご利用ありがとうございました。',
	'settings.delete.account.error': '退会処理中にエラーが発生しました。時間をおいて再度お試しください。',
	'settings.delete.account.password': '現在のパスワードを入力してください',
	'settings.delete.account.password.placeholder': 'パスワードを入力',
	'settings.delete.account.auth.error': '認証に失敗しました。パスワードを確認して再度お試しください。',
	'common.cancel': 'キャンセル',
	'settings.delete.account.confirm.button': '退会を確認',
	'search.results': '「{query}」の検索結果',
	'search.with.term': '「{term}」で検索',
	'book.not.found': '本が見つかりませんでした',
	'back.to.home': 'ホームに戻る',
	'book.description': '概要',
	'book.details': '詳細情報',
	'book.author': '著者',
	'book.publisher': '出版社',
	'book.published.date': '出版日',
	'book.tags': 'タグ',

	// Meta tag messages
	'meta.default.description': 'デザインに関する本が探しやすいサイトです。',
	'meta.home.title': 'デザイン本を発見する',
	'meta.tag.title': '{tag}のデザイン本',
	'meta.tag.description': '{tag}のデザイン本を集めたコレクションです。',
	'meta.book.description': '"{title}" by {author} - このデザイン本の詳細情報です。',
	'meta.search.title': '「{query}」の検索結果',
	'meta.search.description': '「{query}」のデザイン本を集めたコレクションです。',
	'meta.about.title': 'このサイトについて',
	'meta.about.description': 'デザインに関する本が探しやすいサイトです。UI/UX、グラフィック、タイポグラフィなど、さまざまなジャンルの本を紹介します。',
	'meta.settings.title': 'アカウント設定',
	'meta.settings.description': 'Design Book Collectionのアカウント設定とプロフィール情報を管理します。',
	'meta.favorites.title': 'お気に入りの本',
	'meta.favorites.description': 'お気に入りの本のコレクションを表示します。',
};

export default messages; 