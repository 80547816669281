import TagManager from 'react-gtm-module';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// GTMのIDを環境変数から取得
const GTM_ID = 'GTM-WNQ76PCK';

// GTMの初期化
export const initGTM = () => {
	if (GTM_ID) {
		TagManager.initialize({
			gtmId: GTM_ID
		});
	}
};

// ページビューを送信するカスタムフック
export const usePageTracking = () => {
	const location = useLocation();

	useEffect(() => {
		// ページビューイベントをデータレイヤーにプッシュ
		if (window.dataLayer) {
			window.dataLayer.push({
				event: 'page_view',
				page: {
					path: location.pathname + location.search,
					title: document.title
				}
			});
		}
	}, [location]);
};

// GTMコンポーネント
const GTMTracking = () => {
	usePageTracking();
	return null;
};

export default GTMTracking;