import React from 'react';
import { Link } from 'react-router-dom';

const UserMenu = ({ isMenuOpen, setIsMenuOpen, currentLocale, handleSignOut }) => {
	return (
		<div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 z-10 border border-gray-100">
			<div className="absolute -top-2 right-3 w-4 h-4 bg-white transform rotate-45 border-l border-t border-gray-100"></div>
			<Link
				to={`/favorites`}
				className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 cursor-pointer"
				onClick={() => setIsMenuOpen(false)}
			>
				お気に入りの本
			</Link>
			<Link
				to={`/settings`}
				className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 cursor-pointer"
				onClick={() => setIsMenuOpen(false)}
			>
				設定
			</Link>
			<button
				onClick={() => {
					handleSignOut();
					setIsMenuOpen(false);
				}}
				className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 cursor-pointer"
			>
				ログアウト
			</button>
		</div>
	);
};

export default UserMenu; 