import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import FavoriteButton from '../Favorite/FavoriteButton';
import { useBooks } from '../../hooks/useBooks';
import Header from '../_common/Header';

const BookDetail = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const intl = useIntl();
	const { books, loading } = useBooks();
	const locale = intl.locale;

	// URLパラメータのidを数値に変換して比較
	const book = books.find(b => b.id === Number(id));

	if (loading) {
		return (
			<div className="container mx-auto px-4 py-8">
				<div className="text-center">
					<h1 className="text-2xl font-bold mb-4">Loading...</h1>
				</div>
			</div>
		);
	}

	if (!book) {
		return (
			<div className="container mx-auto px-4 py-8">
				<div className="text-center">
					<h1 className="text-2xl font-bold mb-4">
						{intl.formatMessage({ id: 'book.not.found' })}
					</h1>
					<button
						onClick={() => navigate('/')}
						className="text-blue-500 hover:text-blue-600"
					>
						{intl.formatMessage({ id: 'back.to.home' })}
					</button>
				</div>
			</div>
		);
	}

	return (
		<>
			<Helmet>
				<title>{book.title}</title>
				<meta name="description" content={intl.formatMessage(
					{ id: 'meta.book.description' },
					{ title: book.title, author: book.author }
				)} />
				<meta property="og:title" content={book.title} />
				<meta property="og:description" content={intl.formatMessage(
					{ id: 'meta.book.description' },
					{ title: book.title, author: book.author }
				)} />
				<meta property="og:type" content="article" />
				<meta property="og:url" content={`https://design-book-collection.com${locale === 'en' ? '/en' : ''}/books/${book.id}`} />
				{book.image && <meta property="og:image" content={book.image} />}
			</Helmet>
			<div className="">
				<Header />
				<div className="flex-1 container mx-auto px-4 py-8 overflow-y-auto min-h-screen">
					<div className="max-w-4xl mx-auto">
						<div className="flex flex-col items-center md:items-start md:flex-row gap-8">
							<div className="w-1/2 md:w-1/3">
								<img
									src={book.image}
									alt={book.title}
									className="w-full object-contain shadow-lg"
								/>
							</div>
							<div className="w-full md:w-2/3">
								<h1 className="text-lg font-bold mb-4">{book.title}</h1>
								<div className="space-y-4">
									<div>
										<dl className="grid grid-cols-1 gap-2 text-sm">
											<div className="flex gap-1">
												<dt className="font-medium min-w-fit">
													{intl.formatMessage({ id: 'book.author' })}:
												</dt>
												<dd>{book.author}</dd>
											</div>
											<div className="flex gap-1">
												<dt className="font-medium">
													{intl.formatMessage({ id: 'book.published.date' })}:
												</dt>
												<dd>{new Date(book.publishedDate).toLocaleDateString()}</dd>
											</div>
										</dl>
									</div>
									{book.tags && book.tags.length > 0 && (
										<div>
											<div className="flex flex-wrap gap-2">
												{book.tags.map(tag => (
													<span
														key={tag}
														className="px-3 py-1 bg-gray-100 rounded-full text-xs"
													>
														{tag}
													</span>
												))}
											</div>
										</div>
									)}
									<FavoriteButton bookId={book.id} />
									{book.amazonUrl && (
										<a href={book.amazonUrl} target="_blank" rel="noopener noreferrer" className="grid place-items-center w-full border border-gray-300 px-4 py-2 rounded-md hover:bg-gray-100 transition-colors duration-300">
											<img src="/assets/amazon.png" alt="Amazon" className="h-10" />
										</a>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BookDetail; 