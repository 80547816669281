import React from 'react';
import { useIntl } from 'react-intl';
import LanguageSwitcher from '../../_common/LanguageSwitcher';
import { useLocale } from '../../../contexts/LocaleContext';

const SideBar = ({ availableTags, selectedTags, handleTagSelect }) => {
	const intl = useIntl();
	const { locale, handleLanguageChange } = useLocale();
	// タグをソートする関数
	const sortTags = (tags) => {
		return tags.sort((a, b) => {
			const labelA = getTagLabel(a);
			const labelB = getTagLabel(b);
			if (locale === 'ja') {
				return labelA.localeCompare(labelB, 'ja', { sensitivity: 'base' });
			} else {
				return labelA.localeCompare(labelB, 'en');
			}
		});
	};

	const getTagLabel = (tag) => {
		return tag === 'all'
			? intl.formatMessage({ id: 'tag.all' })
			: intl.formatMessage({ id: `tag.${tag.toLowerCase()}` });
	};

	const sortedTags = ['all', ...sortTags(availableTags)];

	return (
		<div className="w-48 h-full overflow-y-auto scrollbar-hide px-2 py-4 bg-slate-50 border-r border-gray-200">
			<div className="grid gap-1">
				{sortedTags.map(tag => (
					<button
						key={tag}
						onClick={() => handleTagSelect(tag)}
						className={`transition-all duration-300 text-left px-4 py-2 text-xs whitespace-nowrap rounded-md ${(tag === 'all' && selectedTags.length === 0) || selectedTags.includes(tag)
							? ' text-primary bg-slate-200 hover:bg-slate-300'
							: ' text-primary hover:bg-slate-200'
							}`}
					>
						{getTagLabel(tag)}
					</button>
				))}
			</div>
			<div className=" p-4 flex flex-col gap-4 items-start">
				<div className="flex flex-col gap-2">
					<ul className="flex space-x-4">
						<li>
							<a className="text-xs hover:underline" href="/about">
								{intl.formatMessage({ id: 'nav.about' })}
							</a>
						</li>
					</ul>
					<p className="text-[10px]">© 2025 Design Book Collection</p>
				</div>
				<LanguageSwitcher onLanguageChange={handleLanguageChange} currentLocale={locale} />
			</div>
		</div>
	);
};

export default SideBar;