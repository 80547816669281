import React, { createContext, useState, useContext } from 'react';

const LocaleContext = createContext();

export function LocaleProvider({ children }) {
	const [locale, setLocale] = useState(() => {
		const browserLang = navigator.language.toLowerCase();
		return browserLang.startsWith('ja') ? 'ja' : 'en';
	});

	const handleLanguageChange = (newLocale) => {
		setLocale(newLocale);
		const currentPath = window.location.pathname;
		const pathSegments = currentPath.split('/').filter(Boolean);

		if (pathSegments.length > 0 && pathSegments[0] !== 'en' && pathSegments[0] !== 'about') {
			const newPath = newLocale === 'en' ? `/en/${pathSegments[0]}` : `/${pathSegments[0]}`;
			window.history.pushState({}, '', newPath);
		} else {
			const newPath = newLocale === 'en' ? '/en' : '/';
			window.history.pushState({}, '', newPath);
		}
	};

	return (
		<LocaleContext.Provider value={{ locale, handleLanguageChange }}>
			{children}
		</LocaleContext.Provider>
	);
}

export function useLocale() {
	const context = useContext(LocaleContext);
	if (context === undefined) {
		throw new Error('useLocale must be used within a LocaleProvider');
	}
	return context;
} 