import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import BookGrid from '../Main/BookGrid';
import NotFoundBook from '../Main/NotFoundBook';
import Header from '../_common/Header';

const SearchResults = ({ books }) => {
	const [searchParams] = useSearchParams();
	const query = searchParams.get('q');
	const intl = useIntl();
	const locale = intl.locale;

	const filteredBooks = books.filter(book =>
		book.title.toLowerCase().includes(query?.toLowerCase() || '') ||
		book.author.toLowerCase().includes(query?.toLowerCase() || '')
	);

	return (
		<>
			<Helmet>
				<title>{intl.formatMessage({ id: 'meta.search.title' }, { query })}</title>
				<meta name="description" content={intl.formatMessage({ id: 'meta.search.description' }, { query })} />
				<meta property="og:title" content={intl.formatMessage({ id: 'meta.search.title' }, { query })} />
				<meta property="og:description" content={intl.formatMessage({ id: 'meta.search.description' }, { query })} />
				<meta property="og:url" content={`https://design-book-collection.com${locale === 'en' ? '/en' : ''}/search?q=${encodeURIComponent(query)}`} />
			</Helmet>
			<div className="flex flex-col min-h-screen overflow-y-auto">
				<Header />
				<div className="container mx-auto px-4 py-8">
					<h1 className="text-2xl font-bold mb-6">
						{intl.formatMessage(
							{ id: 'search.results' },
							{
								query: query,
								count: filteredBooks.length
							}
						)}
					</h1>
					{filteredBooks.length > 0 ? (
						<BookGrid books={filteredBooks} />
					) : (
						<NotFoundBook />
					)}
				</div>
			</div>
		</>
	);
};

export default SearchResults; 