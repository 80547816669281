import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useAuth } from '../../contexts/AuthContext';
import { useFavorites } from '../../hooks/useFavorites';
import { useBooks } from '../../hooks/useBooks';
import BookGrid from '../Main/BookGrid';
import { Navigate } from 'react-router-dom';
import { useLocale } from '../../contexts/LocaleContext';
import { Helmet } from 'react-helmet-async';
import Header from '../_common/Header';

const FavoriteBooks = () => {
  const { locale } = useLocale();
  const intl = useIntl();
  const { user } = useAuth();
  const { favorites, loading: favoritesLoading } = useFavorites();
  const { books, loading: booksLoading } = useBooks();
  const [favoriteBooks, setFavoriteBooks] = useState([]);

  useEffect(() => {
    if (!favoritesLoading && !booksLoading) {
      if (favorites && favorites.length > 0) {
        const favoriteBookIds = favorites.map(fav => fav.bookId);
        const filteredBooks = books.filter(book => favoriteBookIds.includes(book.id));
        setFavoriteBooks(filteredBooks);
      } else {
        setFavoriteBooks([]);
      }
    }
  }, [favorites, books, favoritesLoading, booksLoading]);

  if (!user) {
    return <Navigate to={locale === 'en' ? '/en/signin' : '/signin'} />;
  }

  if (favoritesLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>お気に入りの本 | Design Book Collection</title>
        <meta name="description" content="お気に入りに登録したデザインの本の一覧です。" />
        <meta property="og:title" content="お気に入りの本 | Design Book Collection" />
        <meta property="og:description" content="お気に入りに登録したデザインの本の一覧です。" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://design-book-collection.com/favorites" />
      </Helmet>
      <div className="flex flex-col min-h-screen">
        <Header />
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-2xl font-bold mb-6">
            {intl.formatMessage({ id: 'favorite.title' })}
          </h1>
          {favoriteBooks.length > 0 ? (
            <BookGrid books={favoriteBooks} />
          ) : (
            <div className="text-center text-gray-500">
              {intl.formatMessage({ id: 'favorite.empty' })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FavoriteBooks; 