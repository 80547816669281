import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/config';
import { doc, setDoc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { useIntl } from 'react-intl';
import { useAuth } from '../../contexts/AuthContext';

const FavoriteButton = ({ bookId }) => {
	const [isFavorite, setIsFavorite] = useState(false);
	const intl = useIntl();
	const { user } = useAuth();

	useEffect(() => {
		if (!user) {
			setIsFavorite(false);
			return;
		}

		// お気に入りの状態をリアルタイムで監視
		const favoriteRef = doc(db, 'favorites', `${user.uid}_${bookId}`);
		const unsubscribe = onSnapshot(favoriteRef, (doc) => {
			setIsFavorite(doc.exists());
		});

		return () => unsubscribe();
	}, [bookId, user]);

	const toggleFavorite = async () => {
		if (!user) {
			alert(intl.formatMessage({ id: 'favorite.login.required' }));
			return;
		}

		const favoriteRef = doc(db, 'favorites', `${user.uid}_${bookId}`);

		try {
			if (isFavorite) {
				await deleteDoc(favoriteRef);
			} else {
				await setDoc(favoriteRef, {
					userId: user.uid,
					bookId: bookId,
					createdAt: new Date()
				});
			}
		} catch (error) {
			console.error('Error toggling favorite:', error);
		}
	};

	return (
		<button
			onClick={toggleFavorite}
			className={`rounded-full ${isFavorite ? 'text-red-500' : 'text-gray-400'
				} hover:text-red-500 transition-colors`}
			aria-label={intl.formatMessage({
				id: isFavorite ? 'favorite.remove' : 'favorite.add'
			})}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="h-5 w-5"
				fill={isFavorite ? 'currentColor' : 'none'}
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
				/>
			</svg>
		</button>
	);
};

export default FavoriteButton; 