import React from 'react';

const LanguageSwitcher = ({ onLanguageChange, currentLocale }) => (
	<button
		onClick={() => onLanguageChange(currentLocale === 'ja' ? 'en' : 'ja')}
		className="text-sm text-gray-900"
	>
		{currentLocale === 'ja' ? 'English' : '日本語'}
	</button>
);

export default LanguageSwitcher;