import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import {
	deleteUser,
	reauthenticateWithCredential,
	EmailAuthProvider,
	GoogleAuthProvider,
	reauthenticateWithPopup
} from 'firebase/auth';
import { Helmet } from 'react-helmet-async';
import Header from '../_common/Header';

const Settings = () => {
	const intl = useIntl();
	const { user } = useAuth();
	const navigate = useNavigate();
	const [isDeleting, setIsDeleting] = useState(false);
	const [error, setError] = useState('');
	const [password, setPassword] = useState('');
	const [showPasswordDialog, setShowPasswordDialog] = useState(false);
	const locale = intl.locale;

	// ユーザーが未ログインの場合はホームページにリダイレクト
	React.useEffect(() => {
		if (!user) {
			navigate('/');
		}
	}, [user, navigate]);

	const handleReauthenticate = async () => {
		try {
			console.log('Starting reauthentication process...');
			console.log('Provider ID:', user.providerData[0].providerId);

			if (user.providerData[0].providerId === 'google.com') {
				const provider = new GoogleAuthProvider();
				await reauthenticateWithPopup(user, provider);
				return true;
			} else {
				if (!password) {
					console.log('Password required, showing password dialog');
					setShowPasswordDialog(true);
					return false;
				}
				console.log('Attempting email reauthentication...');
				const credential = EmailAuthProvider.credential(user.email, password);
				await reauthenticateWithCredential(user, credential);
				console.log('Email reauthentication successful');
				return true;
			}
		} catch (error) {
			console.error('Detailed reauthentication error:', error.code, error.message);
			setError(intl.formatMessage({ id: 'settings.delete.account.auth.error' }) + `: ${error.message}`);
			return false;
		}
	};

	const handleDeleteAccount = async () => {
		if (showPasswordDialog) {
			// パスワードダイアログが表示されている場合は、パスワードを使用して再認証を試みる
			setIsDeleting(true);
			setError('');

			try {
				console.log('Starting account deletion with password...');
				const isReauthenticated = await handleReauthenticate();
				console.log('Reauthentication result:', isReauthenticated);

				if (!isReauthenticated) {
					console.log('Reauthentication failed');
					setIsDeleting(false);
					return;
				}

				console.log('Attempting to delete user account...');
				await deleteUser(user);
				console.log('User account deleted successfully');
				alert(intl.formatMessage({ id: 'settings.delete.account.success' }));
				navigate('/');
			} catch (error) {
				console.error('Detailed error in account deletion:', error.code, error.message);
				setError(intl.formatMessage({ id: 'settings.delete.account.error' }) + `: ${error.message}`);
			} finally {
				setIsDeleting(false);
				setShowPasswordDialog(false);
				setPassword('');
			}
		} else {
			// 初回の退会ボタンクリック時
			if (!window.confirm(intl.formatMessage({ id: 'settings.delete.account.confirm' }))) {
				return;
			}

			setIsDeleting(true);
			setError('');

			try {
				console.log('Starting initial account deletion process...');
				const isReauthenticated = await handleReauthenticate();
				console.log('Initial reauthentication result:', isReauthenticated);

				if (!isReauthenticated) {
					// パスワードダイアログを表示する場合は、ここで処理を終了
					setIsDeleting(false);
					return;
				}

				// Google認証の場合は直接削除処理に進む
				console.log('Attempting to delete user account...');
				await deleteUser(user);
				console.log('User account deleted successfully');
				alert(intl.formatMessage({ id: 'settings.delete.account.success' }));
				navigate('/');
			} catch (error) {
				console.error('Detailed error in account deletion:', error.code, error.message);
				setError(intl.formatMessage({ id: 'settings.delete.account.error' }) + `: ${error.message}`);
			} finally {
				if (!showPasswordDialog) {
					setIsDeleting(false);
				}
			}
		}
	};

	if (!user) {
		return null;
	}

	return (
		<>
			<Helmet>
				<title>{intl.formatMessage({ id: 'meta.settings.title' })}</title>
				<meta name="description" content={intl.formatMessage({ id: 'meta.settings.description' })} />
				<meta property="og:title" content={intl.formatMessage({ id: 'meta.settings.title' })} />
				<meta property="og:description" content={intl.formatMessage({ id: 'meta.settings.description' })} />
				<meta property="og:url" content={`https://design-book-collection.com${locale === 'en' ? '/en' : ''}/settings`} />
			</Helmet>
			<div className="flex flex-col min-h-screen">
				<Header />
				<div className="container mx-auto px-4 py-8">
					<h1 className="text-2xl font-bold mb-6">
						{intl.formatMessage({ id: 'settings.title' })}
					</h1>
					<div className="bg-white rounded-lg shadow p-6">
						<div className="mb-6">
							<h2 className="text-lg font-semibold mb-4">
								{intl.formatMessage({ id: 'settings.profile' })}
							</h2>
							<div className="space-y-4">
								<div>
									<label className="block text-sm font-medium text-gray-700">
										{intl.formatMessage({ id: 'settings.email' })}
									</label>
									<p className="mt-1 text-sm text-gray-900">{user.email}</p>
								</div>
							</div>
						</div>

						<div className="border-t pt-6">
							<h2 className="text-lg font-semibold mb-4 text-red-600">
								{intl.formatMessage({ id: 'settings.delete.account' })}
							</h2>
							<p className="text-sm text-gray-600 mb-4">
								{intl.formatMessage({ id: 'settings.delete.account.description' })}
							</p>
							{error && (
								<p className="text-sm text-red-600 mb-4">{error}</p>
							)}
							{showPasswordDialog && (
								<div className="mb-4">
									<label className="block text-sm font-medium text-gray-700 mb-2">
										{intl.formatMessage({ id: 'settings.delete.account.password' })}
									</label>
									<input
										type="password"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500"
										placeholder={intl.formatMessage({ id: 'settings.delete.account.password.placeholder' })}
									/>
									<div className="mt-4 flex space-x-4">
										<button
											onClick={handleDeleteAccount}
											disabled={!password || isDeleting}
											className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed"
										>
											{isDeleting
												? '処理中...'
												: intl.formatMessage({ id: 'settings.delete.account.confirm.button' })}
										</button>
										<button
											onClick={() => {
												setShowPasswordDialog(false);
												setPassword('');
											}}
											className="bg-gray-200 hover:bg-gray-300 text-gray-700 px-4 py-2 rounded-md text-sm font-medium"
										>
											{intl.formatMessage({ id: 'common.cancel' })}
										</button>
									</div>
								</div>
							)}
							{!showPasswordDialog && (
								<button
									onClick={handleDeleteAccount}
									disabled={isDeleting}
									className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed"
								>
									{isDeleting
										? '処理中...'
										: intl.formatMessage({ id: 'settings.delete.account.button' })}
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Settings; 
