import React, { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import FavoriteButton from '../Favorite/FavoriteButton';
import { useNavigate, useLocation } from 'react-router-dom';

const BookItem = ({ book }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [imageHeight, setImageHeight] = useState(200);
	const navigate = useNavigate();
	const location = useLocation();
	const isEnglish = location.pathname.startsWith('/en');

	const handleImageLoad = (event) => {
		setIsLoading(false);
		const aspectRatio = event.target.naturalWidth / event.target.naturalHeight;
		const width = event.target.width;
		setImageHeight(width / aspectRatio);
	};

	const handleItemClick = (e) => {
		// お気に入りボタンのクリックイベントが伝播しないようにする
		if (e.target.closest('.favorite-button-wrapper')) {
			return;
		}
		const prefix = isEnglish ? '/en' : '';
		navigate(`${prefix}/books/${book.id}`);
	};

	return (
		<div
			onClick={handleItemClick}
			className="text-left w-full cursor-pointer"
			role="button"
			tabIndex={0}
			onKeyPress={(e) => {
				if (e.key === 'Enter' || e.key === ' ') {
					handleItemClick(e);
				}
			}}
		>
			<div className="relative h-[160px] drop-shadow-[0_0_1px_rgba(0,0,0,0.3)] mb-2">
				{isLoading && (
					<Skeleton
						variant="rectangular"
						width="100%"
						height={160}
						className="mb-2"
					/>
				)}
				<img
					src={book.image}
					alt={book.title}
					className={`w-full h-full object-bottom object-contain mb-2 transition-opacity duration-300 ${isLoading ? 'opacity-0' : 'opacity-100'
						}`}
					onLoad={handleImageLoad}
				/>
			</div>
			<div>
				<h3 className="text-xs mb-1 line-clamp-3">{book.title}</h3>
				<div className="favorite-button-wrapper">
					<FavoriteButton bookId={book.id} />
				</div>
			</div>
		</div>
	);
};

export default BookItem;