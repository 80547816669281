import React, { useState } from 'react';
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../../firebase/config';
import { useIntl } from 'react-intl';
import { useNavigate, Link } from 'react-router-dom';
import GoogleSignInButton from './GoogleSignInButton';
import Divider from './Divider';

const SignUp = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [error, setError] = useState('');
	const intl = useIntl();
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			setError(intl.formatMessage({ id: 'auth.password.mismatch' }));
			return;
		}
		try {
			await createUserWithEmailAndPassword(auth, email, password);
			navigate('/');
		} catch (error) {
			setError(error.message);
		}
	};

	const handleGoogleSignIn = async () => {
		try {
			const provider = new GoogleAuthProvider();
			await signInWithPopup(auth, provider);
			navigate('/');
		} catch (error) {
			setError(error.message);
		}
	};

	return (
		<div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
			<h2 className="text-2xl font-bold mb-6">
				{intl.formatMessage({ id: 'auth.signup' })}
			</h2>
			{error && <p className="text-red-500 mb-4">{error}</p>}
			<form onSubmit={handleSubmit} className="mb-4">
				<div className="mb-4">
					<label className="block text-gray-700 mb-2" htmlFor="email">
						{intl.formatMessage({ id: 'auth.email' })}
					</label>
					<input
						type="email"
						id="email"
						className="w-full px-3 py-2 border rounded-lg"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						required
					/>
				</div>
				<div className="mb-4">
					<label className="block text-gray-700 mb-2" htmlFor="password">
						{intl.formatMessage({ id: 'auth.password' })}
					</label>
					<input
						type="password"
						id="password"
						className="w-full px-3 py-2 border rounded-lg"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required
					/>
				</div>
				<div className="mb-6">
					<label className="block text-gray-700 mb-2" htmlFor="confirmPassword">
						{intl.formatMessage({ id: 'auth.password.confirm' })}
					</label>
					<input
						type="password"
						id="confirmPassword"
						className="w-full px-3 py-2 border rounded-lg"
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
						required
					/>
				</div>
				<button
					type="submit"
					className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
				>
					{intl.formatMessage({ id: 'auth.signup.button' })}
				</button>
			</form>

			<Divider>
				{intl.formatMessage({ id: 'auth.or' })}
			</Divider>

			<GoogleSignInButton onClick={handleGoogleSignIn}>
				{intl.formatMessage({ id: 'auth.signup.google' })}
			</GoogleSignInButton>

			<div className="mt-6 text-center">
				<p className="text-gray-600">
					{intl.formatMessage({ id: 'auth.have.account' })}
					{' '}
					<Link to="/signin" className="text-blue-500 hover:text-blue-600 font-medium">
						{intl.formatMessage({ id: 'auth.signin.link' })}
					</Link>
				</p>
			</div>
		</div>
	);
};

export default SignUp; 
