import React from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import Footer from '../_common/Footer';
import AboutSection from './AboutSection';

const About = () => {
	const intl = useIntl();
	const locale = intl.locale;

	return (
		<>
			<Helmet>
				<title>{intl.formatMessage({ id: 'meta.about.title' })}</title>
				<meta name="description" content={intl.formatMessage({ id: 'meta.about.description' })} />
				<meta property="og:title" content={intl.formatMessage({ id: 'meta.about.title' })} />
				<meta property="og:description" content={intl.formatMessage({ id: 'meta.about.description' })} />
				<meta property="og:url" content={`https://design-book-collection.com${locale === 'en' ? '/en' : ''}/about`} />
			</Helmet>
			<div className="h-full overflow-y-auto">
				<div className="p-8">
					<AboutSection title="このサイトについて">
						このサイトは、デザインに関する書籍を集めたコレクションです。
						さまざまなジャンルの書籍を紹介し、デザインの知識を深める手助けをします。
					</AboutSection>
					<AboutSection title="情報の正確性について">
						当サイトは、提供する情報に関して正確性や最新性の維持に努めていますが、その内容の完全性や正確性を保証するものではありません。掲載された情報やリンク先の内容により生じた損害等について、一切の責任を負いかねます。
					</AboutSection>
					<AboutSection title="アフィリエイトプログラムについて">
						当サイトは、Amazon.co.jpを宣伝し、リンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイトプログラムである、Amazonアソシエイト・プログラムの参加者です。
					</AboutSection>
					<AboutSection title="Googleアナリティクスの利用について">
						当サイトでは、ウェブサイトの利用状況を分析し、サービス向上を図るためにGoogleアナリティクス4（GA4）を使用しています。GA4は、Cookieを使用してユーザーの匿名情報を収集します。収集される情報には、閲覧したページ、滞在時間、クリック履歴、IPアドレスなどが含まれます。これらのデータは個人を特定するものではなく、統計的な分析に使用されます。詳細については、Googleのプライバシーポリシーをご参照ください。
					</AboutSection>
					<AboutSection title="Cookieの使用について">
						当サイトでは、ユーザーの利便性向上やアクセス解析のためにCookieを使用しています。Cookieは、ユーザーのブラウザに保存される小さなテキストファイルであり、個人を特定する情報は含まれません。ブラウザの設定により、Cookieの受け入れを拒否することが可能ですが、その場合、当サイトの一部機能がご利用いただけなくなる場合があります。
					</AboutSection>
					<AboutSection title="免責事項の変更について">
						当サイトは、必要に応じて免責事項の内容を予告なく変更することがあります。最新の内容をご確認いただきますようお願いいたします。
					</AboutSection>
				</div>
				<Footer />
			</div>
		</>
	);
};

export default About;