import { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase/config';
import { useAuth } from '../contexts/AuthContext';

export const useFavorites = () => {
	const [favorites, setFavorites] = useState([]);
	const [loading, setLoading] = useState(true);
	const { user } = useAuth();

	useEffect(() => {
		if (!user) {
			setFavorites([]);
			setLoading(false);
			return;
		}

		// favoritesコレクションのクエリを作成
		const favoritesRef = collection(db, 'favorites');

		// ドキュメントIDのプレフィックスでフィルタリング
		const unsubscribe = onSnapshot(
			query(favoritesRef, where('__name__', '>=', user.uid + '_'), where('__name__', '<=', user.uid + '_\uf8ff')),
			(snapshot) => {
				const favoritesData = snapshot.docs.map(doc => ({
					id: doc.id,
					...doc.data()
				}));
				setFavorites(favoritesData);
				setLoading(false);
			},
			(error) => {
				console.error('Error fetching favorites:', error);
				setLoading(false);
			}
		);

		return () => unsubscribe();
	}, [user]);

	return { favorites, loading };
}; 