import React from 'react';

const UserAvatar = ({ onClick }) => {
	return (
		<button
			onClick={onClick}
			className="flex items-center gap-2 hover:text-gray-600 transition-colors focus:outline-none"
		>
			<div className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center">
				<svg
					className="w-5 h-5 text-gray-500"
					fill="currentColor"
					viewBox="0 0 24 24"
				>
					<path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
				</svg>
			</div>
		</button>
	);
};

export default UserAvatar; 